import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";

const Location = (props) => {
  const { data } = props;
  if (!data) return null;
  const { page } = data;

  return (
    <div style={{ overflow: "hidden" }}>
      <Seo page={page} />
      <Layout>
        <iframe
          src={page.data.map_url}
          className="map"
          style={{ border: 0 }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query locationQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicLocation {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        map_url
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
      }
    }
  }
`;

export default Location;
